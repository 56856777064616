@charset "UTF-8";
/*

font-family: 'Poppins', sans-serif;


*/
body {
  color: #000;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-size: 0.95rem;
}
@media (min-width: 1200px) {
  body {
    font-size: 1.0925rem;
  }
}
@media (min-width: 1440px) {
  body {
    font-size: 1.1875rem;
  }
}
@media (min-width: 1920px) {
  body {
    font-size: 1.235rem;
  }
}
@media (min-width: 3840px) {
  body {
    font-size: 1.33rem;
  }
}

a {
  text-decoration: none;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  color: #8a8a8a;
}
a:hover {
  text-decoration: none;
  color: #8a8a8a;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: #3e3e3e;
}

p.h1,
p.h2,
p.h3,
p.h4,
p.h5,
p.h6 {
  font-weight: 400 !important;
}

li,
p {
  color: #757575;
}

.vertical-divider:after {
  content: "";
  position: absolute;
  top: 20%;
  right: 50%;
  margin-right: -2px;
  height: 60%;
  width: 4px;
  background-color: #000;
  display: block;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-rounded {
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}

.border-5 {
  border: 5px solid #ffffff;
}

@media (min-width: 1440px) {
  h1,
  .h1 {
    font-size: 3.5625rem;
  }

  h2,
  .h2 {
    font-size: 3.0875rem;
  }

  h3,
  .h3 {
    font-size: 2.6125rem;
  }

  h4,
  .h4 {
    font-size: 1.9rem;
  }

  h5,
  .h5 {
    font-size: 1.615rem;
  }

  .h6 {
    font-size: 1.425rem;
  }
}
@media (min-width: 1600.98px) {
  h1,
  .h1 {
    font-size: 4.275rem;
  }

  h2,
  .h2 {
    font-size: 3.8rem;
  }

  h3,
  .h3 {
    font-size: 3.0875rem;
  }

  h4,
  .h4 {
    font-size: 2.375rem;
  }

  h5,
  .h5 {
    font-size: 1.9rem;
  }

  .h6 {
    font-size: 1.6625rem;
  }
}
canvas[resize] {
  width: 100%;
  height: 100%;
}

section {
  width: 100%;
}

.scroll-top-bottom {
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  right: 30px;
  background: transparent;
  bottom: 30px;
  cursor: pointer;
  z-index: 500;
}
.scroll-top-bottom.white .arrow-cone {
  border-color: #fff !important;
}
.scroll-top-bottom.white .animate-line {
  background-color: #fff !important;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes downMove {
  0% {
    top: 0px;
    height: 0px;
  }
  100% {
    bottom: 20px;
    height: 20px;
  }
}
@keyframes downMove {
  0% {
    top: 0px;
    height: 0px;
  }
  100% {
    bottom: 20px;
    height: 20px;
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes upMove {
  0% {
    bottom: 20px;
    height: 20px;
  }
  100% {
    top: 0px;
    height: 0px;
  }
}
@keyframes upMove {
  0% {
    bottom: 20px;
    height: 20px;
  }
  100% {
    top: 0px;
    height: 0px;
  }
}
.heading-xl {
  font-size: 2.75rem;
}

.heading-lg {
  font-size: 2.25em;
}

.heading-md {
  font-size: 1.75em;
}

.heading-sm {
  font-size: 1.25em;
}

@media (min-width: 576px) {
  .heading-xl {
    font-size: 3.5rem;
  }

  .heading-lg {
    font-size: 2.75em;
  }

  .heading-md {
    font-size: 2em;
  }

  .heading-sm {
    font-size: 1.5em;
  }
}
@media (min-width: 1024px) {
  .heading-xl {
    font-size: 5rem;
  }

  .heading-lg {
    font-size: 4em;
  }

  .heading-md {
    font-size: 3em;
  }

  .heading-sm {
    font-size: 2em;
  }
}
@media (min-width: 1600.98px) {
  .heading-xl {
    font-size: 6rem;
  }

  .heading-lg {
    font-size: 5em;
  }

  .heading-md {
    font-size: 4em;
  }

  .heading-sm {
    font-size: 3em;
  }
}
.container {
  z-index: 1;
}
@media (min-width: 1440px) {
  .container {
    max-width: 1300px;
  }
}
@media (min-width: 1600.98px) {
  .container {
    max-width: 1360px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1500px;
  }
}

*:focus {
  outline: 0;
}

.full-height {
  min-height: 60vh;
}

@media (min-width: 1024px) {
  .full-height {
    min-height: 100vh;
  }
}
@media (min-width: 1600.98px) {
  .full-height {
    min-height: 100vh;
  }
}
@media (min-width: 1920px) {
  .full-height {
    min-height: 100vh;
  }
}
.scroll-top {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  opacity: 0;
  display: none;
  width: 40px;
  height: 40px;
  color: #000;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.1);
}

.scroll-top:hover {
  background-color: #ff8943;
  color: #ffffff;
}

.social-icons a {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  display: block;
  color: #000;
}
.social-icons a span {
  align-self: center;
  font-size: 1.25rem;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
}
.social-icons a.facebook:focus,
.social-icons a.facebook:hover {
  color: #3b5999;
}
.social-icons a.twitter:focus,
.social-icons a.twitter:hover {
  color: #55acee;
}
.social-icons a.linkedin:hover,
.social-icons a.linkedin:focus {
  color: #0077b5;
}
.social-icons a.instagram:focus,
.social-icons a.instagram:hover {
  color: #eb1f81;
}
.social-icons a.youtube:focus,
.social-icons a.youtube:hover {
  color: #cd201f;
}

.facebook-text {
  color: #3b5999 !important;
}

.twitter-text {
  color: #55acee !important;
}

.linkedin-text {
  color: #0077b5 !important;
}

.instagram-text {
  color: #bc0d5f !important;
}

.youtube-text {
  color: #cd201f !important;
}

.bg-light-blue {
  background-color: #e1f3ff !important;
}

.bg-cream {
  background-color: #fafafa !important;
}

.bg-blue {
  background-color: #34349a !important;
}

.bg-dark-blue {
  background-color: #000e6c !important;
}

.bg-gradient {
  background: #4d62a9;
  background: -moz-linear-gradient(90deg, #4d62a9 0%, #4387ac 100%);
  background: -webkit-linear-gradient(90deg, #4d62a9 0%, #4387ac 100%);
  background: linear-gradient(90deg, #4d62a9 0%, #4387ac 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d62a9",endColorstr="#4387ac",GradientType=1);
}

.bg-none {
  background-color: transparent !important;
}

.border-primary {
  border-color: #40769b !important;
}

.bg-red {
  background-color: #f50d48 !important;
}

.text-red {
  color: #f50d48 !important;
}

.text-light-gray {
  color: #f6f6f6 !important;
}

.bg-light-gray {
  background-color: #f6f6f6 !important;
}

.bg-light-gray2 {
  background-color: #f7f7f7;
}

.bg-deep-red {
  background-color: #c40923 !important;
}

.bg-primary {
  background-color: #40769b !important;
}

.bg-secondary {
  background-color: #d7e2ea !important;
}

.font-weight-light {
  font-weight: 100 !important;
}

.text-gray {
  color: #c4c5c5 !important;
}

.text-secondary {
  color: #d7e2ea !important;
}

.text-blue {
  color: #34349a !important;
}

.text-light-primary {
  color: #79a8c8;
}

.text-primary {
  color: #40769b !important;
}

.text-black {
  color: #000 !important;
}

.text-yellow {
  color: #ffd037 !important;
}

.p-sm {
  font-size: 13px;
}

.pointer {
  cursor: pointer;
}

.video-block {
  line-height: 0;
}

.content-box {
  padding: 1.5rem;
}
@media (min-width: 1024px) {
  .content-box {
    padding: 2.5rem;
  }
}

.content-box-px {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
@media (min-width: 1024px) {
  .content-box-px {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

.content-box-py {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .content-box-py {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.section-heading {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .section-heading {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1440px) {
  .section-heading {
    margin-bottom: 3rem;
  }
}

.section-heading-over {
  position: relative;
  z-index: 5;
  transform: translateY(50%);
}

.page-wrapper {
  display: block;
  position: relative;
}

.round {
  -webkit-border-radius: 0.75rem !important;
  -moz-border-radius: 0.75rem !important;
  border-radius: 0.75rem !important;
}

.round-full {
  -webkit-border-radius: 25rem !important;
  -moz-border-radius: 25rem !important;
  border-radius: 25rem !important;
}

.round-lg {
  -webkit-border-radius: 1.3rem !important;
  -moz-border-radius: 1.3rem !important;
  border-radius: 1.3rem !important;
}
@media (min-width: 1024px) {
  .round-lg {
    -webkit-border-radius: 2rem !important;
    -moz-border-radius: 2rem !important;
    border-radius: 2rem !important;
  }
}

.round-xl {
  -webkit-border-radius: 1.75rem !important;
  -moz-border-radius: 1.75rem !important;
  border-radius: 1.75rem !important;
}
@media (min-width: 1024px) {
  .round-xl {
    -webkit-border-radius: 3rem !important;
    -moz-border-radius: 3rem !important;
    border-radius: 3rem !important;
  }
}

.round-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.round-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.round-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.round-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.round-top-left-0 {
  border-top-left-radius: 0 !important;
}

.round-top-right-0 {
  border-top-right-radius: 0 !important;
}

.round-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

.round-bottom-right-0 {
  border-bottom-right-radius: 0 !important;
}

.round-bottom-left-1 {
  border-bottom-left-radius: 1rem;
}

.round-bottom-right-1 {
  border-bottom-right-radius: 1rem;
}

.round-top-left-1 {
  border-top-left-radius: 1rem;
}

.round-top-right-1 {
  border-bottom-left-radius: 1rem;
}

.red-link {
  color: #f50d48;
  font-weight: 600;
}
.red-link:hover {
  color: #c40923;
}

p {
  margin-bottom: 0.25rem;
}
@media (min-width: 1024px) {
  p {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1600.98px) {
  p {
    margin-bottom: 1rem;
  }
}

.btn {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  position: relative;
}

.btn-feet:before {
  content: "";
  height: 45px;
  width: 45px;
  -webkit-transform: translateY(-54%) translateX(-6px);
  -ms-transform: translateY(-54%) translateX(-6px);
  transform: translateY(-54%) translateX(-6px);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.btn-feet.--blue:before {
  background-image: url(./images/home/feet-icon-blue.png);
}
.btn-feet.--light-blue:before {
  background-image: url(./images/home/feet-icon-light-blue.png);
}

.btn-lg {
  padding: 0.5rem 0.75rem;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  max-height: 63px;
}
@media (min-width: 768px) {
  .btn-lg {
    padding: 0.6rem 1rem;
  }
}
@media (min-width: 1200px) {
  .btn-lg {
    padding: 0.7rem 1.25rem;
  }
}
@media (min-width: 1440px) {
  .btn-lg {
    padding: 0.8rem 1rem;
    font-size: 1.5rem;
  }
}

.btn-xl {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}
@media (min-width: 576px) {
  .btn-xl {
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .btn-xl {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
}

.btn-outline-secondary {
  color: #000;
  border-color: #000;
}
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  background-color: #c4c5c5;
  border-color: #c4c5c5;
  color: #ffffff;
}

.btn-outline-primary {
  color: #40769b;
  border-color: #40769b;
  border-width: 2px;
}
.btn-outline-primary.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: rgba(64, 118, 155, 0.9);
  border-color: rgba(64, 118, 155, 0.9);
  -webkit-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
}

.btn-outline-success {
  color: #40cb2d;
  border-width: 2px;
  border-color: #40cb2d;
}
.btn-outline-success.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success.show > .btn-outline-success.dropdown-toggle,
.btn-outline-success:active,
.btn-outline-success:focus,
.btn-outline-success:hover {
  color: #ffffff !important;
  background-color: rgba(64, 203, 45, 0.9);
  border-color: rgba(64, 203, 45, 0.9);
  -webkit-box-shadow: 0 5px 15px rgba(64, 203, 45, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(64, 203, 45, 0.4);
  box-shadow: 0 5px 15px rgba(64, 203, 45, 0.4);
}

.btn-primary {
  color: rgba(246, 246, 246, 0.9);
  background: #40769b;
  border-color: transparent;
  -webkit-box-shadow: 0 15px 15px rgba(64, 118, 155, 0.2);
  -moz-box-shadow: 0 15px 15px rgba(64, 118, 155, 0.2);
  box-shadow: 0 15px 15px rgba(64, 118, 155, 0.2);
}
.btn-primary.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary.show > .btn-primary.dropdown-toggle,
.btn-primary:visited,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  color: #ffffff;
  background-color: rgba(64, 118, 155, 0.9);
  border-color: rgba(64, 118, 155, 0.9);
  -webkit-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.8);
  -moz-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.8);
  box-shadow: 0 5px 15px rgba(64, 118, 155, 0.8);
}

.btn-blue {
  color: #ffffff;
  background: #34349a;
  border-color: #34349a;
}
.btn-blue.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue.show > .btn-blue.dropdown-toggle,
.btn-blue:visited,
.btn-blue:active,
.btn-blue:focus,
.btn-blue:hover {
  color: #ffffff;
  background-color: rgba(52, 52, 154, 0.9);
  border-color: rgba(52, 52, 154, 0.9);
  -webkit-box-shadow: 0 5px 15px rgba(52, 52, 154, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(52, 52, 154, 0.4);
  box-shadow: 0 5px 15px rgba(52, 52, 154, 0.4);
}

.circle-btn {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  display: inline-flex;
  background: #ffffff;
  justify-content: center;
}
.circle-btn i {
  align-self: center;
}
.circle-btn.circle-btn-lg {
  height: 3.5rem;
  width: 3.5rem;
}

.addfixbtn {
  position: absolute;
  top: 4rem;
  right: 0;
  -webkit-border-radius: 3rem 0 0 3rem;
  -moz-border-radius: 3rem 0 0 3rem;
  border-radius: 3rem 0 0 3rem;
  font-weight: bold;
}

.filterbycheck-menu {
  margin: 0;
  padding: 0;
}
.filterbycheck-menu li {
  list-style-type: none;
  margin-top: 4px;
  margin-bottom: 4px;
}
.filterbycheck-menu li.custom-checkbox input + label {
  padding-left: 3rem;
}

.selectbycheck-menu {
  margin: 0;
  padding: 0;
}
.selectbycheck-menu li {
  list-style-type: none;
  margin-top: 4px;
  margin-bottom: 4px;
}
.selectbycheck-menu li.custom-checkbox input + label:before {
  right: 0 !important;
  left: auto;
}

.custom-checkbox.bordered input + label:before {
  background-color: transparent;
  border: 1px solid #f6f6f6;
}
.custom-checkbox input {
  opacity: 0;
  position: absolute;
}
.custom-checkbox input:checked + label:before {
  content: "";
  color: #40769b;
}
.custom-checkbox input + label {
  position: relative;
  width: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
}
.custom-checkbox input + label:before {
  content: "";
  font-family: "themify";
  height: 30px;
  width: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 3px 6px;
  background-color: rgba(64, 118, 155, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.btn-light-primary {
  color: #40769b;
  background-color: #d7e2ea;
  border-color: #d7e2ea;
}
.btn-light-primary:active,
.btn-light-primary:focus,
.btn-light-primary:hover {
  color: #40769b;
  background-color: rgba(215, 226, 234, 0.9);
  border-color: rgba(215, 226, 234, 0.9);
  -webkit-box-shadow: 0 5px 15px rgba(215, 226, 234, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(215, 226, 234, 0.4);
  box-shadow: 0 5px 15px rgba(215, 226, 234, 0.4);
}

.btn-light {
  color: #f50d48;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  color: #f50d48;
  background-color: #ffffff;
  border-color: #ffffff;
  -webkit-box-shadow: 0 5px 15px rgba(246, 246, 246, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(246, 246, 246, 0.4);
  box-shadow: 0 5px 15px rgba(246, 246, 246, 0.4);
}

.shadow-yellow {
  box-shadow: 0 15px 35px rgba(253, 178, 32, 0.5);
}

.shadow-green {
  box-shadow: 0 15px 35px rgba(64, 206, 15, 0.5);
}

.shadow-red {
  box-shadow: 0 15px 35px rgba(228, 7, 50, 0.5);
}

.shadow-blue {
  box-shadow: 0 15px 35px rgba(27, 87, 159, 0.5);
}

.shadow-brown {
  box-shadow: 0 15px 35px rgba(179, 113, 54, 0.5);
}

.shadow-primary {
  -webkit-box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
  -moz-box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
  box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
}

.shadow-primary-sm {
  -webkit-box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
  -moz-box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
  box-shadow: 0 0 35px rgba(64, 118, 155, 0.5);
}

.invert-white {
  filter: grayscale(100%) brightness(0) invert(100%);
  -webkit-filter: grayscale(100%) brightness(0) invert(100%);
}

.invert-gray {
  filter: grayscale(1) brightness(8);
  -webkit-filter: grayscale(1) brightness(8);
}

.invert-gray2 {
  filter: brightness(0.8);
  -webkit-filter: brightness(0.8);
}

.zoom--75 {
  transform: scale(0.75);
}

.invert-black {
  filter: brightness(0);
  -webkit-filter: brightness(0);
}

.btn-link {
  font-weight: 600;
  text-decoration: none;
  padding-right: 0;
  padding-left: 0;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  text-transform: capitalize;
}
.btn-link:hover {
  text-decoration: none;
  letter-spacing: 0.5px;
  color: #40769b;
}

.main-heading {
  display: block;
  position: relative;
  padding: 0.25rem 0;
  margin-bottom: 0.5rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .main-heading {
    padding: 0.75rem 0;
  }
}
.main-heading .small {
  font-weight: 900;
  font-size: 60%;
}
.main-heading * {
  text-transform: capitalize;
}

.border-white {
  border-color: #ffffff !important;
}

.bg-trans-blue {
  background-color: rgba(0, 14, 108, 0.5);
}

.fa-icon {
  width: 22px;
}
@media (min-width: 1200px) {
  .fa-icon {
    width: 28px;
  }
}

@media (min-width: 1200px) {
  .form-control {
    height: calc(1.75em + 1rem + 2px);
  }

  .form-control-lg {
    height: calc(2.25em + 1rem + 2px);
  }
}
.title-underline {
  position: relative;
  display: inline-block;
}
.title-underline span {
  position: relative;
  z-index: 3;
}
.title-underline::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: 0;
  background-color: #d3e3ed;
}

.dropdown-menu {
  min-width: 300px;
}

@media (min-width: 1440px) {
  .form-group {
    margin-bottom: 2rem;
  }
}

[data-toggle="offcanvas"] {
  cursor: pointer;
}

.radio-dots input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.radio-dots input[type="radio"]:checked + label {
  color: #000;
}
.radio-dots input[type="radio"]:checked + label:after {
  height: 12px;
  width: 12px;
  content: "";
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  bottom: 4px;
  left: 4px;
  background-color: #f50d48;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.radio-dots input[type="radio"] + label {
  padding-left: 1.5rem;
  margin-left: 0;
  color: #000;
  cursor: pointer;
  position: relative;
}
.radio-dots input[type="radio"] + label:before {
  height: 20px;
  width: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  border: 2px solid #f50d48;
}
.radio-dots button {
  cursor: default;
}
.radio-dots .btn-outline-secondary {
  font-weight: 400;
  font-size: 14px;
  color: #c4c5c5;
  border-color: #c4c5c5;
}
.radio-dots .btn-outline-secondary:active,
.radio-dots .btn-outline-secondary:focus,
.radio-dots .btn-outline-secondary:hover {
  background-color: transparent;
  color: #000;
}

.custom-group-input.pill .form-control {
  color: #b9b9b9;
}

.error {
  color: red;
}

.ui-slider-horizontal {
  height: 0.3em;
  border-radius: 0;
  background-color: rgba(196, 197, 197, 0.4);
  border-color: transparent !important;
}

.ui-slider .ui-slider-range {
  background-color: #40769b;
  border-color: #40769b;
  margin-top: 0px;
  height: 0.35em;
}

.ui-slider .ui-slider-handle {
  margin-top: -4px;
  background-color: #40769b;
  border: 5px solid #88a9c0;
  border-radius: 100%;
  box-shadow: 0;
}
.ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.popover {
  max-width: 400px !important;
  border: 0;
  padding: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1rem #f0f0f0;
  -moz-box-shadow: 0 0 1rem #f0f0f0;
  box-shadow: 0 0 1rem #f0f0f0;
}

.radio-pill input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.radio-pill input[type="radio"]:checked + label {
  background-color: #40769b;
  color: #ffffff;
  border-color: #40769b;
}
.radio-pill input[type="radio"] + label {
  position: relative;
  color: #c4c5c5;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  border: 1.5px solid #c4c5c5;
}

.custom-group-input {
  position: relative;
  width: 100%;
}
.custom-group-input.pill .form-control {
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  border-width: 2px;
  border-color: #f6f6f6;
}
.custom-group-input.rounded .form-control {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  border-width: 2px;
  border-color: #f6f6f6;
}
.custom-group-input.rounded-digonal .form-control {
  -webkit-border-radius: 0.75rem 1.5rem 0.75rem 1.5rem;
  -moz-border-radius: 0.75rem 1.5rem 0.75rem 1.5rem;
  border-radius: 0.75rem 1.5rem 0.75rem 1.5rem;
  border-width: 2px;
  border-color: #c4c5c5;
}
@media (min-width: 1366px) {
  .custom-group-input.rounded-digonal .form-control {
    -webkit-border-radius: 1rem 2rem 1rem 2rem;
    -moz-border-radius: 1rem 2rem 1rem 2rem;
    border-radius: 1rem 2rem 1rem 2rem;
  }
}
.custom-group-input .form-control {
  background-color: transparent;
  border-color: transparent transparent #f6f6f6;
  border-width: 0 0 2px;
  border-radius: 0rem;
  width: 100%;
  height: calc(2.25em + 0.75rem + 2px);
  font-size: 13px;
}
@media (min-width: 1366px) {
  .custom-group-input .form-control {
    font-size: 15px;
    height: calc(2.5em + 0.75rem + 2px);
  }
}
@media (min-width: 1440px) {
  .custom-group-input .form-control {
    font-size: 16px;
    height: calc(3em + 0.75rem + 2px);
  }
}
.custom-group-input .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #c4c5c5;
  text-transform: capitalize;
}
.custom-group-input .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c5c5;
  text-transform: capitalize;
}
.custom-group-input .form-control::placeholder {
  color: #c4c5c5;
  text-transform: capitalize;
}
.custom-group-input .form-control.error,
.custom-group-input .form-control.active,
.custom-group-input .form-control:focus {
  box-shadow: 0 0 0 transparent;
}
.custom-group-input.both-icon .form-control {
  padding-left: 3rem;
  padding-right: 3rem;
}
.custom-group-input.left-icon .form-control {
  padding-left: 3rem;
}
.custom-group-input.right-icon .form-control {
  padding-right: 3rem;
}
.custom-group-input select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  outline: 0;
  appearance: none;
}
.custom-group-input .group-icon-left,
.custom-group-input .group-icon-right {
  z-index: 10;
  padding: 5px 8px;
  color: #c4c5c5;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.custom-group-input .group-icon-left i,
.custom-group-input .group-icon-right i {
  display: inline-block;
  align-self: center;
}
.custom-group-input .group-icon-left {
  position: absolute;
  top: 50%;
  left: 6px;
}
.custom-group-input .group-icon-right {
  position: absolute;
  top: 50%;
  right: 6px;
}

.placeholder-white::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
}
.placeholder-white:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}
.placeholder-white::placeholder {
  color: #ffffff !important;
}

.placeholder-sm::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
}
.placeholder-sm:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
}
.placeholder-sm::placeholder {
  font-size: 12px;
}

.profile-pic {
  width: 70px;
  height: 70px;
  display: inline-block;
}

.file-upload {
  display: none;
}

.img-gallery .img-item {
  -ms-flex: 0 0 10% !important;
  flex: 0 0 10% !important;
  padding-right: 0;
  max-width: 10% !important;
}
.img-gallery img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.imageuploadify {
  position: relative;
  min-height: 60px;
  min-width: auto;
  max-width: 1000px;
  margin: auto;
  display: flex;
  padding: 0;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  color: #3aa0ff;
}

.imageuploadify .imageuploadify-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  display: none;
  font-size: 7em;
  background-color: rgba(242, 242, 242, 0.7);
  text-align: center;
  pointer-events: none;
}

.imageuploadify .imageuploadify-overlay i {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.imageuploadify .imageuploadify-images-list {
  display: inline-flex;
}

.imageuploadify .imageuploadify-images-list i {
  display: block;
  font-size: 7em;
  text-align: center;
  margin-top: 0.5em;
  padding-bottom: 12px;
}

.imageuploadify .imageuploadify-images-list span.imageuploadify-message {
  font-size: 24px;
  border-top: 1px solid #3aa0ff;
  border-bottom: 1px solid #3aa0ff;
  padding: 10px;
  display: inline-block;
}

.imageuploadify .imageuploadify-images-list button.btn-default {
  display: block;
  color: #3aa0ff;
  border-color: #3aa0ff;
  border-radius: 1em;
  margin: 25px auto;
  width: 100%;
  max-width: 500px;
}

.imageuploadify .imageuploadify-images-list .imageuploadify-container {
  width: 70px;
  height: 70px;
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;
  float: left;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #888;
}

.imageuploadify
  .imageuploadify-images-list
  .imageuploadify-container
  button.btn-danger {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  font-size: 10px;
  line-height: 1.42;
  padding: 2px 0;
  text-align: center;
  z-index: 3;
}

.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.imageuploadify
  .imageuploadify-images-list
  .imageuploadify-container
  .imageuploadify-details {
  position: absolute;
  top: 0;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  opacity: 0;
}

.imageuploadify
  .imageuploadify-images-list
  .imageuploadify-container
  .imageuploadify-details
  span {
  display: block;
}

.switch-btn {
  position: relative;
  width: 40px;
  height: 8px;
  margin: 0 auto;
  border-radius: 10px;
}
.switch-btn input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 40px;
  height: 38px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

/* Third toggle btn */
.switch-btn span {
  display: block;
  width: 40px;
  height: 26px;
  background-color: #ddd;
  border-radius: 20px;
  transition: 0.5s ease background-color;
}

.switch-btn span:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: #a3a3a3;
  border-radius: 50%;
  box-shadow: 2px 6px 25px #d7d7d7;
  transform: translate(0px, 0px);
  transition: 0.6s ease transform, 0.6s box-shadow;
}

.switch-btn input[type="checkbox"]:checked + span {
  background-color: #c1d7e6;
}

.switch-btn input[type="checkbox"]:checked + span:before {
  box-shadow: 7px 6px 25px #e6e6e6;
  transform: translate(15px, 0px);
  background-color: #40769b;
}

.switch-btn input[type="checkbox"]:checked + span:after {
  width: 24px;
  border: 1px solid red;
  border-radius: 50%;
  transform: translate(25px, 0px);
}

.rowMarginHalf {
  margin-left: -7px;
  margin-right: -7px;
}
.rowMarginHalf.colpad-half .col,
.rowMarginHalf.colpad-half .col-1,
.rowMarginHalf.colpad-half .col-10,
.rowMarginHalf.colpad-half .col-11,
.rowMarginHalf.colpad-half .col-12,
.rowMarginHalf.colpad-half .col-2,
.rowMarginHalf.colpad-half .col-3,
.rowMarginHalf.colpad-half .col-4,
.rowMarginHalf.colpad-half .col-5,
.rowMarginHalf.colpad-half .col-6,
.rowMarginHalf.colpad-half .col-7,
.rowMarginHalf.colpad-half .col-8,
.rowMarginHalf.colpad-half .col-9,
.rowMarginHalf.colpad-half .col-auto,
.rowMarginHalf.colpad-half .col-lg,
.rowMarginHalf.colpad-half .col-lg-1,
.rowMarginHalf.colpad-half .col-lg-10,
.rowMarginHalf.colpad-half .col-lg-11,
.rowMarginHalf.colpad-half .col-lg-12,
.rowMarginHalf.colpad-half .col-lg-2,
.rowMarginHalf.colpad-half .col-lg-3,
.rowMarginHalf.colpad-half .col-lg-4,
.rowMarginHalf.colpad-half .col-lg-5,
.rowMarginHalf.colpad-half .col-lg-6,
.rowMarginHalf.colpad-half .col-lg-7,
.rowMarginHalf.colpad-half .col-lg-8,
.rowMarginHalf.colpad-half .col-lg-9,
.rowMarginHalf.colpad-half .col-lg-auto,
.rowMarginHalf.colpad-half .col-md,
.rowMarginHalf.colpad-half .col-md-1,
.rowMarginHalf.colpad-half .col-md-10,
.rowMarginHalf.colpad-half .col-md-11,
.rowMarginHalf.colpad-half .col-md-12,
.rowMarginHalf.colpad-half .col-md-2,
.rowMarginHalf.colpad-half .col-md-3,
.rowMarginHalf.colpad-half .col-md-4,
.rowMarginHalf.colpad-half .col-md-5,
.rowMarginHalf.colpad-half .col-md-6,
.rowMarginHalf.colpad-half .col-md-7,
.rowMarginHalf.colpad-half .col-md-8,
.rowMarginHalf.colpad-half .col-md-9,
.rowMarginHalf.colpad-half .col-md-auto,
.rowMarginHalf.colpad-half .col-sm,
.rowMarginHalf.colpad-half .col-sm-1,
.rowMarginHalf.colpad-half .col-sm-10,
.rowMarginHalf.colpad-half .col-sm-11,
.rowMarginHalf.colpad-half .col-sm-12,
.rowMarginHalf.colpad-half .col-sm-2,
.rowMarginHalf.colpad-half .col-sm-3,
.rowMarginHalf.colpad-half .col-sm-4,
.rowMarginHalf.colpad-half .col-sm-5,
.rowMarginHalf.colpad-half .col-sm-6,
.rowMarginHalf.colpad-half .col-sm-7,
.rowMarginHalf.colpad-half .col-sm-8,
.rowMarginHalf.colpad-half .col-sm-9,
.rowMarginHalf.colpad-half .col-sm-auto,
.rowMarginHalf.colpad-half .col-xl,
.rowMarginHalf.colpad-half .col-xl-1,
.rowMarginHalf.colpad-half .col-xl-10,
.rowMarginHalf.colpad-half .col-xl-11,
.rowMarginHalf.colpad-half .col-xl-12,
.rowMarginHalf.colpad-half .col-xl-2,
.rowMarginHalf.colpad-half .col-xl-3,
.rowMarginHalf.colpad-half .col-xl-4,
.rowMarginHalf.colpad-half .col-xl-5,
.rowMarginHalf.colpad-half .col-xl-6,
.rowMarginHalf.colpad-half .col-xl-7,
.rowMarginHalf.colpad-half .col-xl-8,
.rowMarginHalf.colpad-half .col-xl-9,
.rowMarginHalf.colpad-half .col-xl-auto {
  padding-right: 7px;
  padding-left: 7px;
}

/* Generated by http://responsive-css.spritegen.com Responsive CSS Sprite Generator */
.i20px {
  width: 20px;
  height: 20px;
}

.i30px {
  width: 30px;
  height: 30px;
}

.i25px {
  width: 25px;
  height: 25px;
}

.i35px {
  width: 35px;
  height: 35px;
}

.i40px {
  width: 40px;
  height: 40px;
}

.i45px {
  width: 45px;
  height: 45px;
}

.i15px {
  width: 15px;
  height: 15px;
}

.i50px {
  width: 50px;
  height: 50px;
}

.i70px {
  width: 70px;
  height: 70px;
}

.icamera,
.ifb,
.iin,
.iinsta,
.itw,
.idogfeetc,
.iappdownload,
.ilocationcircle,
.idogfeet,
.iclock,
.icalendar,
.iangledown,
.imapmarker,
.close,
.iuserman,
.ienvelope,
.imobflag,
.ilcoation,
.ipassword {
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/home/sprite-img.png");
  display: inline-block;
}

.icamera {
  background-position: 0 0%;
  background-size: 100%;
}

.ifb {
  background-position: 0 7.212205%;
  background-size: 100%;
}

.iin {
  background-position: 0 14.424411%;
  background-size: 100%;
}

.iinsta {
  background-position: 0 21.636616%;
  background-size: 100%;
}

.itw {
  background-position: 0 28.848821%;
  background-size: 102.040816%;
}

.idogfeetc {
  background-position: 0 35.519126%;
  background-size: 108.695652%;
}

.iappdownload {
  background-position: 0 41.403026%;
  background-size: 113.636364%;
}

.ilocationcircle {
  background-position: 0 47.599451%;
  background-size: 116.27907%;
}

.idogfeet {
  background-position: 0 53.197279%;
  background-size: 138.888889%;
}

.iclock {
  background-position: 0 58.367347%;
  background-size: 138.888889%;
}

.icalendar {
  background-position: 0 63.537415%;
  background-size: 138.888889%;
}

.iangledown {
  background-position: 0 68.707483%;
  background-size: 138.888889%;
}

.imapmarker {
  background-position: 0 73.877551%;
  background-size: 138.888889%;
}

.close {
  background-position: 0 79.047619%;
  background-size: 138.888889%;
}

.iuserman {
  background-position: 0 83.535762%;
  background-size: 166.666667%;
}

.ienvelope {
  background-position: 0 87.854251%;
  background-size: 166.666667%;
}

.imobflag {
  background-position: 0 92.17274%;
  background-size: 166.666667%;
}

.ilcoation {
  background-position: 0 96.231494%;
  background-size: 178.571429%;
}

.ipassword {
  background-position: 0 100%;
  background-size: 192.307692%;
}

.ilocationpin,
.ilistview-active,
.ilistview,
.imapview,
.imapviw-active,
.iratingstar,
.iverified,
.ifilterscroll-bar,
.ilocation-point,
.ilocationmarker,
.iexperience,
.istar-small-active,
.istar-smalll-inactive,
.idollarsign {
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/home/service-provider.png");
  display: inline-block;
}

.ilocationpin {
  background-position: 0 0%;
  background-size: 100%;
}

.ilistview {
  background-position: 0 24%;
  background-size: 129.166667%;
}
.ilistview.active,
.ilistview:hover {
  background-position: 0 15.666667%;
  background-size: 129.166667%;
}

.imapview {
  background-position: 0 32.333333%;
  background-size: 129.166667%;
}
.imapview:hover,
.imapview.active {
  background-position: 0 40.666667%;
  background-size: 129.166667%;
}

.iratingstar {
  background-position: 0 55.775578%;
  background-size: 147.619048%;
}
.iratingstar.active,
.iratingstar:hover {
  cursor: pointer;
  background-position: 0 48.514851%;
  background-size: 147.619048%;
}

.iverified {
  background-position: 0 62.828947%;
  background-size: 155%;
}

.ifilterscroll-bar {
  background-position: 0 69.508197%;
  background-size: 163.157895%;
}

.ilocation-point {
  background-position: 0 76.065574%;
  background-size: 163.157895%;
}

.ilocationmarker {
  background-position: 0 81.818182%;
  background-size: 193.75%;
}

.iexperience {
  background-position: 0 86.774194%;
  background-size: 221.428571%;
}

.istar-small-active {
  background-position: 0 91.318328%;
  background-size: 238.461538%;
}

.istar-smalll-inactive {
  background-position: 0 95.819936%;
  background-size: 238.461538%;
}

.idollarsign {
  background-position: 0 100%;
  background-size: 258.333333%;
}

.iprofdog-boarder,
.iprofdog-sitter,
.iprofdog-walker,
.iprofselected,
.iprofun-select,
.iprofadd-feet,
.iprofeye,
.iproflikeblk,
.iproftime,
.iprofverifiy,
.iprofstar,
.iprofcheck,
.iprofcake,
.iprofbullot-point,
.iprofclose,
.iprofedit,
.iprofcheck-white {
  max-width: 100%;
  display: inline-block;
  background-size: 100%;
  background-image: url("./images/profile/profile-sprite.png");
}

.iprofdog-boarder {
  background-position: 0 0%;
  background-size: 100%;
}

.iprofdog-sitter {
  background-position: 0 9.943182%;
  background-size: 100%;
}

.iprofdog-walker {
  background-position: 0 19.886364%;
  background-size: 100%;
}

.iprofselected {
  background-position: 0 29.411765%;
  background-size: 117.241379%;
}

.iprofun-select {
  background-position: 0 37.709497%;
  background-size: 121.428571%;
}

.iprofadd-feet {
  background-position: 0 44.931507%;
  background-size: 161.904762%;
}

.iprofeye {
  background-position: 0 50.958904%;
  background-size: 161.904762%;
}

.iproflikeblk {
  background-position: 0 56.986301%;
  background-size: 161.904762%;
}

.iproftime {
  background-position: 0 63.013699%;
  background-size: 161.904762%;
}

.iprofverifiy {
  background-position: 0 69.041096%;
  background-size: 161.904762%;
}

.iprofstar {
  background-position: 0 74.659401%;
  background-size: 178.947368%;
}

.iprofcheck {
  background-position: 0 79.891304%;
  background-size: 188.888889%;
}

.iprofcake {
  background-position: 0 84.823848%;
  background-size: 200%;
}

.iprofbullot-point {
  background-position: 0 88.978495%;
  background-size: 242.857143%;
}

.iprofclose {
  background-position: 0 92.761394%;
  background-size: 261.538462%;
}

.iprofedit {
  background-position: 0 96.514745%;
  background-size: 261.538462%;
}

.iprofcheck-white {
  background-position: 0 100%;
  background-size: 283.333333%;
}

.ibookcompleted,
.ibookcancelled,
.ibookfeatures-dog,
.ibooklocation-pin,
.ibookuser-circle,
.ibookcancelled-blue,
.ibookcancelled-gray,
.ibookcompleted-blue,
.ibookcompleted-gray,
.ibookongoing-blue,
.ibookongoing-gray,
.ibookpending-blue,
.ibookpending-gray,
.ibookfeatures-bag,
.ibookage-cake,
.ibookcalender,
.ibookclock-time,
.ibookdog-type,
.ibookdelete,
.ibookpen {
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/booking-sprite.png");
  display: inline-block;
}

.ibookcompleted {
  background-position: 0 0%;
  background-size: 100%;
}

.ibookcancelled {
  background-position: 0 9.113608%;
  background-size: 106.25%;
}

.ibookfeatures-dog {
  background-position: 0 17.380661%;
  background-size: 161.904762%;
}

.ibooklocation-pin {
  background-position: 0 23.693803%;
  background-size: 161.904762%;
}

.ibookuser-circle {
  background-position: 0 29.333333%;
  background-size: 170%;
}

.ibookcancelled-blue {
  background-position: 0 34.703748%;
  background-size: 178.947368%;
}

.ibookcancelled-gray {
  background-position: 0 39.903265%;
  background-size: 178.947368%;
}

.ibookcompleted-blue {
  background-position: 0 45.102781%;
  background-size: 178.947368%;
}

.ibookcompleted-gray {
  background-position: 0 50.302297%;
  background-size: 178.947368%;
}

.ibookongoing-blue {
  background-position: 0 55.501814%;
  background-size: 178.947368%;
}

.ibookongoing-gray {
  background-position: 0 60.70133%;
  background-size: 178.947368%;
}

.ibookpending-blue {
  background-position: 0 65.900846%;
  background-size: 178.947368%;
}

.ibookpending-gray {
  background-position: 0 71.100363%;
  background-size: 178.947368%;
}

.ibookfeatures-bag {
  background-position: 0 76.115802%;
  background-size: 188.888889%;
}

.ibookage-cake {
  background-position: 0 80.866426%;
  background-size: 194.285714%;
}

.ibookcalender {
  background-position: 0 85.559567%;
  background-size: 194.285714%;
}

.ibookclock-time {
  background-position: 0 90.252708%;
  background-size: 194.285714%;
}

.ibookdog-type {
  background-position: 0 94.945848%;
  background-size: 194.285714%;
}

.ibookdelete {
  background-position: 0 97.526502%;
  background-size: 425%;
}

.ibookpen {
  background-position: 0 100%;
  background-size: 425%;
}

.iprofile-step-active,
.iprofile-step,
.iswitch-active,
.iswitch-deactive,
.idog-sitter,
.idog-walker,
.idog-border,
.idog-boarder,
.idog-veterinary,
.idog-groomer,
.ivaterinary,
.icalendar2,
.icompany,
.iadd-time,
.iemail,
.iselected-check,
.iexperience,
.icost,
.itime {
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/service-provider-sprite.png");
}

.iprofile-step-active {
  background-position: 0 0%;
  background-size: 100%;
}

.iprofile-step {
  background-position: 0 9.719222%;
  background-size: 100%;
}

.iswitch-active {
  background-position: 0 18.730489%;
  background-size: 115.942029%;
}

.iswitch-deactive {
  background-position: 0 24.453694%;
  background-size: 115.942029%;
}

.idog-sitter {
  background-position: 0 30.851064%;
  background-size: 121.212121%;
}

.idog-walker {
  background-position: 0 38.93617%;
  background-size: 121.212121%;
}

.idog-border {
  background-position: 0 46.921444%;
  background-size: 125%;
}

.idog-boarder {
  background-position: 0 46.921444%;
  background-size: 125%;
}

.idog-groomer {
  background-position: 0 54.77707%;
  background-size: 125%;
}

.ivaterinary {
  background-position: 0 62.632696%;
  background-size: 125%;
}

.idog-veterinary {
  background-position: 0 62.632696%;
  background-size: 125%;
}

.icalendar2 {
  background-position: 0 69.022869%;
  background-size: 181.818182%;
}

.icompany {
  background-position: 0 74.020619%;
  background-size: 210.526316%;
}

.iadd-time {
  background-position: 0 78.762887%;
  background-size: 222.222222%;
}

.iemail {
  background-position: 0 83.505155%;
  background-size: 222.222222%;
}

.iselected-check {
  background-position: 0 88.065844%;
  background-size: 235.294118%;
}

.iexperience {
  background-position: 0 92.213115%;
  background-size: 266.666667%;
}

.icost {
  background-position: 0 96.114519%;
  background-size: 285.714286%;
}

.itime {
  background-position: 0 100%;
  background-size: 285.714286%;
}

.idog-dislike,
.idog-like,
.idog-sit,
.ifilter,
.iac-number,
.ibank,
.icancell,
.ichecked,
.idirection,
.iuser,
.icancelled,
.icompleted,
.ifeet-blue,
.iongoing,
.iupcoming,
.iangle-left,
.iclose,
.irefresh,
.iadded,
.iremover {
  max-width: 100%;
  display: inline-block;
  background-size: 100%;
  background-image: url("./images/service-provider-sprite2.png");
}

.idog-dislike {
  background-position: 0 0%;
  background-size: 100%;
}

.idog-like {
  background-position: 0 6.279734%;
  background-size: 100%;
}

.idog-sit {
  background-position: 0 12.559467%;
  background-size: 100%;
}

.ifilter {
  background-position: 0 18.803419%;
  background-size: 103.703704%;
}

.iac-number {
  background-position: 0 24.740321%;
  background-size: 116.666667%;
}

.ibank {
  background-position: 0 30.217186%;
  background-size: 116.666667%;
}

.icancell {
  background-position: 0 35.694051%;
  background-size: 116.666667%;
}

.ichecked {
  background-position: 0 41.170916%;
  background-size: 116.666667%;
}

.idirection {
  background-position: 0 46.647781%;
  background-size: 116.666667%;
}

.iuser {
  background-position: 0 52.124646%;
  background-size: 116.666667%;
}

.icancelled {
  background-position: 0 57.38476%;
  background-size: 127.272727%;
}

.icompleted {
  background-position: 0 62.464722%;
  background-size: 127.272727%;
}

.ifeet-blue {
  background-position: 0 67.544685%;
  background-size: 127.272727%;
}

.iongoing {
  background-position: 0 72.624647%;
  background-size: 127.272727%;
}

.iupcoming {
  background-position: 0 77.70461%;
  background-size: 127.272727%;
}

.iangle-left {
  background-position: 0 82.474227%;
  background-size: 140%;
}

.iclose {
  background-position: 0 87.160262%;
  background-size: 140%;
}

.irefresh {
  background-position: 0 91.674462%;
  background-size: 143.589744%;
}

.iadded {
  background-position: 0 96.074766%;
  background-size: 147.368421%;
}

.iremover {
  background-position: 0 100%;
  background-size: 175%;
}

.site-header .navbar {
  z-index: 1049;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
}
@media (max-width: 991.98px) {
  .site-header .navbar {
    padding: 0.5rem;
  }
}
@media (min-width: 1366px) {
  .site-header .navbar {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
.site-header .navbar.inner-header {
  background-color: rgba(234, 247, 255, 0.8);
}
.site-header .navbar.sticky-on {
  background-color: rgba(234, 247, 255, 0.9);
  box-shadow: -4px 1px 10px rgba(20, 20, 20, 0.25);
}
@media (max-width: 1199.98px) {
  .site-header .navbar.sticky-on {
    padding: 0.5rem 1rem;
  }
}
.site-header .navbar.sticky-on .navbar-brand {
  max-width: 100px !important;
}
@media (min-width: 1200px) {
  .site-header .navbar.sticky-on .nav-btns > li,
  .site-header .navbar.sticky-on .navbar-nav > li {
    padding: 0 1rem;
  }
}
.site-header .navbar-brand {
  max-width: 55px;
  display: inline-block;
}
@media (min-width: 320.1px) {
  .site-header .navbar-brand {
    max-width: 100px;
  }
}
@media (min-width: 1200px) {
  .site-header .navbar-brand {
    max-width: 150px;
    margin-left: 2rem;
  }
}
.site-header .navbar-brand img {
  max-width: 100%;
}
.site-header .nav-btns > li,
.site-header .navbar-nav > li {
  padding: 0;
}
@media (min-width: 992px) {
  .site-header .nav-btns > li,
  .site-header .navbar-nav > li {
    padding: 1rem 0.5rem;
  }
}
@media (min-width: 1366px) {
  .site-header .nav-btns > li,
  .site-header .navbar-nav > li {
    padding: 2rem 1.25rem;
  }
}
@media (min-width: 1920px) {
  .site-header .nav-btns > li,
  .site-header .navbar-nav > li {
    padding: 3rem 2rem;
  }
}
.site-header .nav-btns > li a,
.site-header .nav-btns > li .nav-link,
.site-header .navbar-nav > li a,
.site-header .navbar-nav > li .nav-link {
  color: #484949;
  position: relative;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 0.5rem 0;
}
@media (min-width: 1366px) {
  .site-header .nav-btns > li a,
  .site-header .nav-btns > li .nav-link,
  .site-header .navbar-nav > li a,
  .site-header .navbar-nav > li .nav-link {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .site-header .nav-btns > li a,
  .site-header .nav-btns > li .nav-link,
  .site-header .navbar-nav > li a,
  .site-header .navbar-nav > li .nav-link {
    font-size: 17px;
  }
}
.site-header .nav-btns > li:hover > a,
.site-header .navbar-nav > li:hover > a {
  color: #40769b;
}
.site-header .nav-btns > li.active > a,
.site-header .navbar-nav > li.active > a {
  color: #40769b;
  position: relative;
}
.site-header .nav-btns > li.active > a::before,
.site-header .navbar-nav > li.active > a::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #40769b;
}
@media (min-width: 1200px) {
  .site-header .navbar-nav li {
    position: relative;
  }
  .site-header .navbar-nav li a {
    -webkit-animation: text-shadow-drop-bottom 0.6s both;
    animation: text-shadow-drop-bottom 0.6s both;
  }
  .site-header .navbar-nav li::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 16px;
    background-color: #484949;
    transform: translateY(-50%);
  }
  .site-header .navbar-nav li:last-child:before {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .site-header .nav-btns li {
    padding-right: 1rem;
  }
  .site-header .nav-btns li i {
    display: inline-block;
  }
}
.site-header .nav-btns a {
  padding: 0.4rem 0.5rem !important;
  border-radius: 1rem;
  color: #40769b !important;
}
@media (min-width: 768px) {
  .site-header .nav-btns a {
    padding: 0.8rem 1rem !important;
  }
}
.site-header .nav-btns .btn-outline-primary:focus,
.site-header .nav-btns .btn-outline-primary:hover {
  color: #ffffff !important;
}

@media (max-width: 1199.98px) {
  .offcanvas-collapse {
    position: fixed;
    z-index: 50;
    top: 0px;
    /* Height of navbar */
    padding-top: 80px;
    padding-left: 1rem;
    padding-right: 1rem;
    bottom: 0;
    width: 300px;
    right: 0;
    overflow-y: auto;
    background-color: #ffffff;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .offcanvas-collapse.open {
    box-shadow: 1px 2px 9px #444;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    /* Account for horizontal padding on navbar */
  }

  .nav-icon {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    position: relative;
    z-index: 60;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
}
@media (max-width: 1199.98px) and (min-width: 576px) {
  .nav-icon {
    margin-top: 9px;
  }
}
@media (max-width: 1199.98px) and (min-width: 1024px) {
  .nav-icon {
    margin-top: 25px;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #40769b;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon span:nth-child(1) {
    top: 6px;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon span:nth-child(2) {
    top: 15px;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon span:nth-child(3) {
    top: 24px;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
@media (max-width: 1199.98px) {
  .nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
}
@media (max-width: 1199.98px) {
  .nav-icon.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}
#main {
  background: #f0f0f0;
  overflow: hidden;
}

.home-banner {
  position: relative;
}
.home-banner .banner-img {
  background: url("./images/home/banner-bg.png");
  background-size: cover;
  background-position: center top;
  width: 100%;
  min-height: 600px;
}
@media (min-width: 768px) {
  .home-banner .banner-img {
    height: 100vh;
  }
}
@media (min-width: 576px) {
  .home-banner .banner {
    position: absolute;
    right: 0;
    bottom: -4rem;
    left: 0;
  }
}
.home-banner .caption {
  margin-top: 10%;
  margin-bottom: 10%;
}
@media (min-width: 576px) {
  .home-banner .caption {
    margin-top: 10%;
    margin-bottom: 0;
  }
}
.home-banner .caption .caption-text {
  background-color: #ffffff;
  padding: 2rem 1rem;
  -webkit-box-shadow: 0 15px 25px rgba(64, 118, 155, 0.2);
  -moz-box-shadow: 0 15px 25px rgba(64, 118, 155, 0.2);
  box-shadow: 0 15px 25px rgba(64, 118, 155, 0.2);
  border: 3px solid #40769b;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}
@media (min-width: 1024px) {
  .home-banner .caption .caption-text {
    padding: 3rem 1.5rem 2rem;
  }
}
.home-banner .caption .hero-form * {
  outline: 0;
}
.home-banner .caption .hero-form label {
  padding-left: 1rem;
}
.home-banner .caption .hero-form .form-control {
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
}
.home-banner .caption .hero-form .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #c4c5c5;
}
.home-banner .caption .hero-form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c5c5;
}
.home-banner .caption .hero-form .form-control::placeholder {
  color: #c4c5c5;
}
.home-banner .caption .hero-form .input-group-lg > .form-control:not(textarea),
.home-banner .caption .hero-form .form-control-lg {
  height: calc(2.5em + 1rem + 2px);
  font-size: 14px;
  font-weight: 600;
}
.home-banner .caption .hero-form input,
.home-banner .caption .hero-form select {
  border-color: #d7e2ea;
  border-width: 2px;
  background-color: rgba(255, 255, 255, 0.7);
}
.home-banner .caption .hero-form input:focus,
.home-banner .caption .hero-form select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}
.home-banner .caption .hero-form .input-group-lg > .form-control {
  border-radius: 1rem 0 0 1rem;
}

.inner-banner {
  background-color: #eaf7ff;
}
@media (min-width: 576px) {
  .inner-banner {
    height: 400px;
  }
}
@media (min-width: 768px) {
  .inner-banner {
    height: 350px;
  }
}
.inner-banner.home-banner .caption .caption-text {
  border: 3px solid transparent !important;
}
@media (max-width: 575.98px) {
  .inner-banner.home-banner .caption .caption-text {
    margin-top: 5rem;
  }
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  outline: 0;
  appearance: none;
  font-weight: 600;
}
select option:checked {
  color: #40769b;
}

.select_div {
  position: relative;
}
.select_div:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  z-index: 0;
  cursor: pointer;
  content: "";
  color: #40769b;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  height: 16px;
  width: 16px;
  font-size: 24px;
}

.user-drop > a {
  border-radius: 1.5rem !important;
  background-color: #ffffff;
  color: #000 !important;
  min-width: 200px;
  position: relative;
}
.user-drop > a img {
  width: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.user-drop > a[aria-expanded="true"] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.user-drop > a[aria-expanded="true"]::after {
  content: "";
}
.user-drop > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  z-index: 0;
  cursor: pointer;
  content: "";
  color: #000;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  height: 16px;
  width: 16px;
  font-size: 20px;
}
.user-drop .dropdown-menu {
  min-width: auto;
  max-width: 100%;
  border: 0;
  padding: 8px;
  margin-top: -1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}
.user-drop .dropdown-menu a {
  display: block;
  border-radius: 0;
  width: 100%;
  border-bottom: 1px solid #c4c5c5;
  color: #000 !important;
}
.user-drop .dropdown-menu a i {
  display: inline-block;
}
.user-drop .dropdown-menu a:last-child {
  border-bottom: 0;
}

.home-services {
  background: url("./images/home/service_background.png");
  background-size: cover;
  background-position: center;
}

.service-item {
  width: 100%;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.service-item .card-body {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 55%;
  text-align: left;
  display: flex;
  justify-content: center;
}
.service-item p {
  color: #ffffff;
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .service-item p {
    font-size: 24px;
    line-height: 30px;
  }
}
.service-item p span {
  display: block;
}
.service-item p.rate {
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .service-item p.rate {
    font-size: 30px;
  }
}

.restaurent-item {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 13px 0 rgba(69, 69, 69, 0.15);
  background-color: #ffffff;
  border-radius: 40px;
}
.restaurent-item .card-img-top {
  border-radius: 40px !important;
  border: solid 4px #ffffff;
}
@media (min-width: 1440px) {
  .restaurent-item .btn {
    padding: 1.5rem 2rem;
  }
}
.restaurent-item p {
  font-weight: 500;
}

.home-how-works .bg-service-img {
  background: url("./images/home/how-it-works-for-customer-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 10em;
}
.home-how-works .text-matter {
  padding: 6em 5em 3em;
}
.home-how-works .text-matter.round-right {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 3em;
}
.home-how-works .text-matter.round-left {
  border-top-left-radius: 10em;
  border-bottom-left-radius: 3em;
}
.home-how-works .text-matter .title {
  margin-bottom: 2rem;
}
.home-how-works .text-matter .title span {
  display: block;
  letter-spacing: 0.2em;
}
.home-how-works .text-matter p {
  font-size: 18px;
  line-height: 30px;
}
@media (min-width: 1024px) {
  .home-how-works .text-matter p {
    font-size: 22px;
    line-height: 40px;
  }
}

.how-work-item {
  width: 100%;
  margin-top: 2rem;
  background-color: transparent;
  text-align: center;
  border: 0;
}
.how-work-item img {
  max-width: 80%;
  margin: auto;
}
.how-work-item .card-body {
  max-width: 50%;
  margin: auto;
}
.how-work-item p {
  font-weight: 600;
}

.home-whychooseus {
  background: url("./images/home/why_choose_us.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.home-whychooseus .content-img-mb-- {
  position: relative;
}
@media (min-width: 768px) {
  .home-whychooseus .content-img-mb-- {
    transform: translateY(30%);
  }
}
@media (min-width: 1024px) {
  .home-whychooseus .content-img-mb-- {
    transform: translateY(20%);
  }
}
.home-whychooseus .content-img-mb-- img {
  border-radius: 3rem;
}
.home-whychooseus .content-img-mb-- .playicon {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -100px;
  margin-top: -100px;
  max-width: 200px;
}
.home-whychooseus p {
  font-size: 17px;
  line-height: 30px;
}
@media (min-width: 1024px) {
  .home-whychooseus p {
    font-size: 20px;
  }
}
.home-whychooseus * {
  color: #ffffff;
}

.home-aboutus {
  background: url("./images/home/about_us_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 80vh;
  padding-top: 10em;
}
.home-aboutus p {
  font-size: 17px;
  line-height: 30px;
}
@media (min-width: 1024px) {
  .home-aboutus p {
    font-size: 20px;
  }
}

.home-getintouch {
  position: relative;
}
.home-getintouch .getintouchimg {
  background: url("./images/home/getin_touch_bg.png");
  background-size: cover;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center;
}
.home-getintouch:after,
.home-getintouch::before {
  content: "";
  position: absolute;
  right: 0;
  z-index: 5;
}
.home-getintouch::before {
  top: -2.2rem;
  width: 50px;
  height: 120px;
  background-position: right;
  background: url("./images/home/dots.png") no-repeat;
}
.home-getintouch::after {
  bottom: 0;
  width: 90px;
  height: 125px;
  background-position: right;
  background: url("./images/home/angles.png") no-repeat;
}
.home-getintouch img {
  z-index: 1;
}
.home-getintouch .contentbg-gradient {
  position: relative;
  background-color: #40769b;
}
.home-getintouch .contentbg-gradient div {
  position: relative;
  z-index: 3;
}
.home-getintouch .contentbg-gradient:before {
  content: "";
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,40769b+70&0+0,1+50 */
  background: -moz-linear-gradient(
    left,
    rgba(125, 185, 232, 0) 0%,
    #5189b1 50%,
    #40769b 70%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(125, 185, 232, 0) 0%,
    #5189b1 50%,
    #40769b 70%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(125, 185, 232, 0) 0%,
    #5189b1 50%,
    #40769b 70%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#007db9e8", endColorstr="#40769b",GradientType=1 );
  /* IE6-9 */
}
.home-getintouch p {
  font-size: 17px;
  line-height: 30px;
}
@media (min-width: 1024px) {
  .home-getintouch p {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .col-xl-5in12 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.price span {
  font-size: 80%;
  color: #c4c5c5;
}

footer {
  background: url("./images/home/footer_bg.png");
  background-size: cover;
  background-position: right bottom;
}
@media (min-width: 992px) {
  footer {
    background-position: left bottom;
  }
}
footer .footer {
  position: relative;
  color: #40769b;
  padding: 2rem 0;
}
@media (min-width: 768px) {
  footer .footer {
    background: url("./images/home/footer-round.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
  }
}
footer .footer .logo {
  max-width: 150px;
}
footer .widget-title {
  color: #40769b;
  display: inline-block;
  margin: 1rem auto;
  position: relative;
  font-size: 18px;
  font-weight: 600;
}
footer .textwidget {
  display: block;
}
@media (max-width: 991.98px) {
  footer .textwidget {
    padding: 0.5rem 0;
  }
}
footer .textwidget .ti {
  font-display: 1.25rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}
footer .textwidget .nav li {
  width: 100%;
  padding: 0.5rem 0;
}
footer .textwidget .nav li a,
footer .textwidget .nav li .nav-link {
  color: #000;
  font-weight: 500;
}
footer .textwidget .nav li a:hover,
footer .textwidget .nav li .nav-link:hover {
  color: #40769b;
}
footer .textwidget .flags {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
footer .textwidget .flags li {
  list-style-type: none;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
footer .textwidget .flags li img {
  object-fit: contain;
}
footer p {
  color: #000;
}
footer a {
  color: #40769b;
}
footer a:hover {
  color: #d7e2ea;
}
footer .social-links a {
  color: #000;
  padding: 0.1rem;
  text-align: center;
}
footer .social-links a span {
  font-size: 1.5rem;
}
footer .social-links a:hover {
  color: #40769b;
}

.social-share a {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #ffffff;
  display: inline-block;
  border: 2px solid transparent;
  margin-right: 5px;
  margin-left: 5px;
  color: #000;
  height: 30px;
  width: 30px;
}
@media (min-width: 1024px) {
  .social-share a {
    height: 40px;
    width: 40px;
  }
  .social-share a:hover {
    color: #ffffff;
  }
}
.social-share a.small-icons a {
  background-color: #f6f6f6;
  width: 20px !important;
  font-size: 12px;
}
.social-share a.facebook:focus,
.social-share a.facebook:hover {
  background-color: #3b5999;
}
.social-share a.twitter:focus,
.social-share a.twitter:hover {
  background-color: #55acee;
}
.social-share a.linkedin:focus,
.social-share a.linkedin:hover {
  background-color: #0077b5;
}
.social-share a.instagram:focus,
.social-share a.instagram:hover {
  background-color: #bc0d5f;
}
.social-share a.youtube:focus,
.social-share a.youtube:hover {
  background-color: #cd201f;
}

@media (min-width: 1366px) {
  .form-popup .modal-xl {
    max-width: 1340px;
  }
}
.form-popup form {
  padding: 1rem 1.5rem;
}
.form-popup .signupas input {
  opacity: 0;
}
.form-popup .signupas input + label {
  margin: 1rem 0;
  cursor: pointer;
}
.form-popup .signupas input + label img {
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
}
.form-popup .signupas input:checked + label img {
  transform: scale(0.99);
  -webkit-transform: scale(0.99);
  -webkit-box-shadow: 0 1rem 1rem #f0f0f0;
  -moz-box-shadow: 0 1rem 1rem #f0f0f0;
  box-shadow: 0 1rem 1rem #f0f0f0;
}
.form-popup .modal-dialog .modal-content {
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  background-color: #fff;
  border-color: transparent;
}
.form-popup .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.form-popup .modal-dialog .modal-content .modal-body .back-btn {
  position: absolute;
  top: 15px;
  left: 15px;
}
.form-popup .modal-dialog .modal-content .modal-body .close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1049;
  height: 42px;
  width: 42px;
  color: #000;
  font-size: 1.15rem;
}
.form-popup .modal-dialog .modal-content .modal-body .close:focus {
  outline: 0;
}
.form-popup .modal-dialog .modal-content .modal-body .close.border {
  border-width: 2px !important;
  border-color: #000 !important;
}
.form-popup .modal-dialog .modal-content .modal-body .form-img {
  position: relative;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: none;
}
@media (min-width: 768px) {
  .form-popup .modal-dialog .modal-content .modal-body .form-img {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
}
.form-popup .modal-dialog .modal-content .modal-body .form-img div img {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
@media (min-width: 768px) {
  .form-popup .modal-dialog .modal-content .modal-body .form-img div img {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
}
.form-popup .modal-dialog .modal-content .modal-body .form-img .logomodal {
  position: absolute;
  top: 12%;
  left: 7%;
  max-width: 200px;
}
.form-popup .modal-dialog .modal-content .modal-body .mobileDrop .form-control {
  padding-left: 8rem;
}
.form-popup .modal-dialog .modal-content .modal-body .form-box {
  min-height: 500px;
  position: relative;
}
.form-popup .modal-dialog .modal-content .modal-body .loginOr {
  text-align: center;
  position: relative;
}
.form-popup .modal-dialog .modal-content .modal-body .loginOr span {
  background-color: #ffffff;
  display: inline-block;
  color: #c4c5c5;
  padding: 0 4px;
  position: relative;
  z-index: 5;
}
.form-popup .modal-dialog .modal-content .modal-body .loginOr:before {
  content: "";
  height: 4px;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -2px;
  background-color: #f6f6f6;
}
.form-popup .modal-dialog .modal-content .modal-body .invalid-tooltip {
  font-size: 10px !important;
  padding: 2px 8px 1px 8px !important;
  border-radius: 20px;
}
.form-popup .modal-dialog .modal-content .modal-body .partitioned {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 0 auto;
}
.form-popup .modal-dialog .modal-content .modal-body .partitioned input {
  height: 40px;
  width: 40px;
  padding: 0;
  border: 2px solid #c4c5c5 !important;
  text-align: center;
  background-color: #fff !important;
  border-radius: 8px;
}
@media (min-width: 1200px) {
  .form-popup .modal-dialog .modal-content .modal-body .partitioned input {
    height: 50px;
    width: 50px;
  }
}
.form-popup
  .modal-dialog
  .modal-content
  .modal-body
  .partitioned
  input:last-child {
  margin-right: 0;
}
.form-popup
  .modal-dialog
  .modal-content
  .modal-body
  .partitioned
  input::-webkit-inner-spin-button,
.form-popup
  .modal-dialog
  .modal-content
  .modal-body
  .partitioned
  input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.form-popup .modal-dialog .modal-content .modal-body .partitioned input:focus,
.form-popup .modal-dialog .modal-content .modal-body .partitioned input.focus {
  border-color: green;
  outline: none;
  box-shadow: none;
}

.service-filter {
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  padding: 1.5rem;
}
.service-filter .ratings i {
  margin-right: 4px;
}
.service-filter .ratings i.i40px {
  width: 30px;
  height: 30px;
}
@media (min-width: 576px) {
  .service-filter .ratings i.i40px {
    width: 40px;
    height: 40px;
  }
}

.socialLogin {
  text-align: center;
}
.socialLogin a {
  padding: 0.5rem;
  display: inline-block;
  width: 30%;
  max-width: 100px;
}
.socialLogin a img {
  max-width: 100%;
  border-radius: 2rem;
  -webkit-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
}
.socialLogin a:focus {
  -webkit-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
}

.gender-group {
  height: 62px;
  border: 1px solid #ddd;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 8px 0 4px;
}
.gender-group div {
  text-align: center;
}
.gender-group input {
  opacity: 0;
}
.gender-group input + label {
  cursor: pointer;
  color: #c4c5c5;
  font-weight: 500;
  margin: 0;
}
.gender-group input:checked + label {
  color: #ffffff;
}
.gender-group input:checked + label span {
  display: none;
}
.gender-group input:checked + .male {
  width: 29px;
  height: 40px;
  background: url("./images/home/sprite-img.png") -125px -5px;
  padding-top: 4px;
}
.gender-group input:checked + .female {
  width: 29px;
  height: 40px;
  background: url("./images/home/sprite-img.png") -59px -63px;
  padding-top: 14px;
}
.gender-group input:checked + .others {
  padding-top: 4px;
  width: 29px;
  height: 29px;
  background: url("./images/home/sprite-img.png") -164px -46px;
}

.crossOutmodal .close {
  color: #ffffff;
  opacity: 1;
  padding: 0;
}
.crossOutmodal .close:focus {
  outline: 0;
}
.crossOutmodal .modal-content {
  border: 0;
  background-color: transparent;
}
.crossOutmodal .modal-header {
  border: 0;
}
.crossOutmodal .modal-body {
  background-color: #ffffff;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}
.crossOutmodal .bg-blue {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.gm-style img {
  width: 100%;
  max-width: none;
}

@media (min-width: 1366px) {
  .provideravtar-xl {
    height: 180px !important;
    width: 180px !important;
  }
  .provideravtar-xl .avtar {
    height: 180px !important;
    width: 180px !important;
  }
  .provideravtar-xl .rating-star {
    margin-top: -3rem !important;
  }
  .provideravtar-xl .rating-star .circlerating {
    height: 70px !important;
    width: 70px !important;
  }
  .provideravtar-xl .rating-star .circlerating i {
    height: 50px !important;
    width: 50px !important;
  }
}
@media (min-width: 768px) and (max-width: 1365.98px) {
  .provideravtar-lg {
    height: 130px !important;
    width: 130px !important;
  }
  .provideravtar-lg .avtar {
    height: 130px !important;
    width: 130px !important;
  }
  .provideravtar-lg .rating-star {
    margin-top: -2.5rem !important;
  }
  .provideravtar-lg .rating-star .circlerating {
    height: 60px !important;
    width: 60px !important;
  }
  .provideravtar-lg .rating-star .circlerating i {
    height: 40px !important;
    width: 40px !important;
  }
}
.provideravtar {
  height: 90px;
  width: 90px;
}
.provideravtar .avtar {
  height: 90px;
  width: 90px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.provideravtar .rating-star {
  margin-top: -1.5rem;
  text-align: center;
  z-index: 2;
  position: relative;
  color: #40769b;
  text-align: center;
}
.provideravtar .rating-star .circlerating {
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  margin-right: auto;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.provideravtar .rating-star .circlerating i {
  align-self: center;
  margin-bottom: 8px;
  height: 25px;
  width: 25px;
}

.serviceItem {
  padding: 1.25rem;
  margin-top: 3rem;
  -webkit-border-radius: 1.5rem 3rem 1.5rem 3rem;
  -moz-border-radius: 1.5rem 3rem 1.5rem 3rem;
  border-radius: 1.5rem 3rem 1.5rem 3rem;
}
.serviceItem .name {
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .serviceItem .name {
    margin-top: 0rem;
  }
}
.serviceItem p {
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.serviceItem p i {
  margin-right: 4px;
}
.serviceItem .gallery {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.serviceItem .gallery img {
  height: 50px;
  width: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-left: -1rem;
  -webkit-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.2);
  -moz-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.2);
  box-shadow: 0 5px 15px rgba(64, 118, 155, 0.2);
}
.serviceItem .gallery div {
  color: #40769b;
}
.serviceItem .btn {
  margin-top: 1.5rem;
}

#servicemap {
  height: 100vh;
}

.profile-banner {
  margin-bottom: 5em;
  position: relative;
  background: url("./images/profile/banner.png");
  background-size: cover;
  background-position: center top;
  width: 100%;
  min-height: 350px;
}
@media (min-width: 768px) {
  .profile-banner {
    height: 50vh;
    margin-bottom: 7.5em;
  }
}
.profile-banner img {
  width: 100%;
}
.profile-banner .profileDetail {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.profile-banner .provideravtar {
  margin: auto;
}

.list-feet {
  margin: 0;
  padding: 0;
}
.list-feet li {
  list-style-type: none;
  position: relative;
  padding-left: 1.7rem;
}
.list-feet li:before {
  content: "";
  background-position: 0 88.978495%;
  background-size: 242.857143%;
  max-width: 100%;
  background-image: url("./images/profile/profile-sprite.png");
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
}

.detail-box {
  padding: 1.5rem;
  position: relative;
}
.detail-box .mx-auto {
  max-width: 95%;
  display: inline-block;
}
@media (min-width: 576px) {
  .detail-box:after {
    content: "";
    width: 1px;
    background-color: #c4c5c5;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -1px;
    margin-top: -10px;
  }
}
.detail-box p {
  display: flex;
  align-items: center;
}
.detail-box p i {
  margin-right: 0.5rem;
}

.details-gallery {
  margin: 1rem 0 0;
  padding: 0;
}
.details-gallery li {
  width: 17%;
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-block;
  margin-right: 0.25rem;
  list-style-type: none;
}
@media (min-width: 768px) {
  .details-gallery li {
    width: 13%;
  }
}
@media (min-width: 1200px) {
  .details-gallery li {
    width: 10.2%;
    margin-right: 0.5rem;
  }
}
.details-gallery li img {
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .details-gallery li img {
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
  }
}

.checkbox-tabs input,
.radio-tabs input {
  opacity: 0;
  position: absolute;
}
.checkbox-tabs label,
.radio-tabs label {
  cursor: pointer;
  font-weight: bold;
  color: #e1f3ff;
  text-transform: capitalize;
}
.checkbox-tabs label i,
.radio-tabs label i {
  min-width: 20px;
}
.checkbox-tabs .label-button,
.radio-tabs .label-button {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f6f6f6;
  color: #000;
  -webkit-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  margin-left: 8px;
  text-align: center;
}
@media (min-width: 992px) {
  .checkbox-tabs .label-button,
  .radio-tabs .label-button {
    padding: 0.8rem 1.25rem;
  }
}
@media (min-width: 1200px) {
  .checkbox-tabs .label-button,
  .radio-tabs .label-button {
    padding: 1.2rem 1.25rem;
  }
}
.checkbox-tabs .label-button.rounded,
.radio-tabs .label-button.rounded {
  border-radius: 0.75rem;
}
@media (min-width: 992px) {
  .checkbox-tabs .label-button.rounded,
  .radio-tabs .label-button.rounded {
    border-radius: 2rem;
  }
}
.checkbox-tabs .label-button.round-top,
.radio-tabs .label-button.round-top {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
@media (min-width: 992px) {
  .checkbox-tabs .label-button.round-top,
  .radio-tabs .label-button.round-top {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
}
.checkbox-tabs .label-button.bgnone,
.radio-tabs .label-button.bgnone {
  background-color: transparent;
}
.checkbox-tabs input.active + label,
.checkbox-tabs input:checked + label,
.radio-tabs input.active + label,
.radio-tabs input:checked + label {
  color: #ffffff;
}
.checkbox-tabs input.active + .label-button,
.radio-tabs input.active + .label-button {
  font-weight: 600;
}
.checkbox-tabs input.active + .label-button,
.checkbox-tabs input:checked + .label-button,
.radio-tabs input.active + .label-button,
.radio-tabs input:checked + .label-button {
  background-color: #40769b;
  color: #ffffff;
  -webkit-box-shadow: 0 0px 30px rgba(64, 118, 155, 0.4);
  -moz-box-shadow: 0 0px 30px rgba(64, 118, 155, 0.4);
  box-shadow: 0 0px 30px rgba(64, 118, 155, 0.4);
}

.radio-select input {
  opacity: 0;
  position: absolute;
}
.radio-select input + label {
  border: 2px solid #c4c5c5;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  color: #40769b;
  margin: 0.5rem 0;
  border-radius: 2rem;
}
@media (min-width: 576px) {
  .radio-select input + label {
    padding: 1rem 0.5rem;
  }
}
.radio-select input[disabled] + label {
  color: #c4c5c5;
  cursor: not-allowed;
}
.radio-select input:checked + label {
  border-color: #40769b;
  background-color: #40769b;
  color: #ffffff;
  -webkit-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
  box-shadow: 0 5px 15px rgba(64, 118, 155, 0.4);
}

.radio-slots label {
  width: 16%;
  min-width: 90px;
}

.ratingsdetails {
  color: #40769b;
}
@media (min-width: 576px) {
  .ratingsdetails i {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .ratingsdetails i {
    font-size: 3rem;
  }
}
@media (min-width: 1024px) {
  .ratingsdetails span {
    font-size: 2rem;
  }
}

.details-rating {
  color: #f6f6f6;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .details-rating i {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .details-rating i {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  .details-rating i {
    font-size: 4rem;
  }
}
.details-rating i:hover,
.details-rating i.active {
  color: #40769b;
}
@media (min-width: 1024px) {
  .details-rating span {
    font-size: 2rem;
  }
}

.review-textarea {
  border-width: 3px;
  border-radius: 2rem;
}

.stats-box {
  padding: 2rem 1.25rem;
  -webkit-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
@media (min-width: 1024px) {
  .stats-box {
    margin: 1rem;
    padding: 3rem 1.25rem;
  }
}

.review-box {
  margin: 1rem;
  padding: 1.25rem;
  -webkit-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  box-shadow: 0 5px 15px rgba(196, 197, 197, 0.4);
  -webkit-border-radius: 1rem 3rem 1rem 3rem;
  -moz-border-radius: 1rem 3rem 1rem 3rem;
  border-radius: 1rem 3rem 1rem 3rem;
}
@media (min-width: 1024px) {
  .review-box {
    margin: 2rem;
    -webkit-border-radius: 1rem 4rem 1rem 4rem;
    -moz-border-radius: 1rem 4rem 1rem 4rem;
    border-radius: 1rem 4rem 1rem 4rem;
  }
}
.review-box .media-body {
  padding: 0 0 0 0;
}
@media (min-width: 768px) {
  .review-box .media-body {
    padding: 0 0 0 1rem;
  }
}
.review-box .avtar {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}
.review-box a {
  font-weight: 500;
}
.review-box p {
  margin-top: 0.5rem;
}
@media (min-width: 1024px) {
  .review-box p {
    font-size: 1.5rem;
  }
}

.avatar-upload {
  position: relative;
  max-width: 100px;
  margin: 20px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 0px;
  z-index: 1;
  bottom: 0px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-flex;
  width: 40px;
  height: 40px;
  padding: 3px;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  border-radius: 100%;
  background: white;
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  color: #40769b;
  font-weight: normal;
  margin-right: -1rem;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:focus,
.avatar-upload .avatar-edit input + label:active,
.avatar-upload .avatar-edit input + label:hover {
  border-color: #40769b;
}
.avatar-upload .avatar-edit input + label i {
  align-self: center;
}
.avatar-upload .avatar-preview {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1023.98px) {
  .custom-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
}
.custom-tabs .nav-item {
  padding: 0 0.5rem;
}
@media (max-width: 1023.98px) {
  .custom-tabs .nav-item {
    white-space: nowrap;
  }
}
.custom-tabs .nav-item:first-child {
  padding-left: 0;
}
.custom-tabs .nav-item:last-child {
  padding-right: 0;
}
.custom-tabs .nav-item .nav-link {
  color: #40769b;
  min-width: 100px;
  background-color: #f6f6f6;
  text-transform: capitalize;
  border-radius: 3rem;
  color: #3e3f49;
  font-weight: 600;
  padding: 0.75rem 0.5rem;
}
@media (min-width: 1200px) {
  .custom-tabs .nav-item .nav-link {
    padding: 1rem 1.5rem;
  }
}
.custom-tabs .nav-item .nav-link:hover,
.custom-tabs .nav-item .nav-link.active {
  box-shadow: none;
  color: #ffffff;
  border-radius: 3rem 3rem 0.5rem 3rem;
  background-color: #40769b;
}

.group-tabs {
  background-color: #f6f6f6;
  -webkit-box-shadow: 0 10px 10px #f8f7f4;
  -moz-box-shadow: 0 10px 10px #f8f7f4;
  box-shadow: 0 10px 10px #f8f7f4;
  border-radius: 2rem;
}
@media (min-width: 576px) {
  .group-tabs {
    border-radius: 6rem;
  }
}
.group-tabs .nav-item {
  padding: 0;
}
.group-tabs .nav-item .nav-link {
  text-align: center;
  color: #c4c5c5;
  position: relative;
  font-weight: normal;
}
.group-tabs .nav-item .nav-link.active {
  border-bottom-right-radius: 1rem;
}
.group-tabs .nav-item .nav-link.active:after {
  display: none;
}
@media (min-width: 576px) {
  .group-tabs .nav-item .nav-link:after {
    content: "";
    width: 1px;
    border-left: 2px solid #c4c5c5;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-right: -1px;
    margin-top: -10px;
  }
}
.group-tabs .nav-item:first-child a:after {
  display: none;
}

.details-tab-content h5 {
  display: flex;
  color: #3e3e3e;
  align-items: center;
}

.ui-datepicker {
  width: auto;
  border: 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
@media (min-width: 576px) {
  .ui-datepicker {
    padding: 1rem;
  }
}
.ui-datepicker .ui-widget {
  z-index: 9999;
  display: none;
  position: relative;
  color: #333;
  background: #fff;
  border-radius: 0;
  padding: 8px;
}
.ui-datepicker.ui-widget-content {
  border: 1px solid #fff;
  -webkit-box-shadow: 0 10px 25px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 10px 25px rgba(196, 197, 197, 0.4);
  box-shadow: 0 10px 25px rgba(196, 197, 197, 0.4);
}

.ui-datepicker.ui-widget .ui-datepicker-header {
  background: 0 0;
  border: 0;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
  background: 0 0;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  background-position: center center;
  border: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next span,
.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev span {
  display: none;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next:hover,
.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev:hover {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
  float: left;
  left: 1rem;
  text-align: left;
  position: relative;
  top: 0px;
  color: #40769b;
  background-image: none;
  opacity: 1;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
    left: 4rem;
    top: 4px;
  }
}
.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  color: #40769b;
  font-size: 1rem;
  font-family: "themify";
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev:before {
    font-size: 2rem;
  }
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next {
  background-image: none;
  float: right;
  text-align: right;
  position: relative;
  right: 1rem;
  background-image: none;
  opacity: 1;
  top: 0px;
  left: auto;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next {
    right: 4rem;
    top: 4px;
  }
}
.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  font-size: 1rem;
  color: #40769b;
  font-family: "themify";
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next:before {
    font-size: 2rem;
  }
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title {
  color: #40769b;
  padding: 5px 3px;
  line-height: 20px;
  font-weight: normal;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title {
    padding: 15px 3px;
    font-size: 1.5rem;
  }
}

.ui-datepicker.ui-widget .ui-datepicker-calendar {
  border: 0;
  margin-bottom: 24px;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th {
  color: #999;
  line-height: 25px;
  font-weight: normal;
  text-align: center;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th span {
  cursor: help;
  border-bottom: 0px dotted #777;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td {
  padding: 0;
  border: 0;
  height: 40px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-calendar td {
    height: 60px;
  }
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled {
  padding: 0;
  border: 0;
}

.ui-datepicker.ui-widget
  .ui-datepicker-calendar
  td.ui-state-disabled
  .ui-state-default:hover {
  background: #f5f5f5;
  color: #666;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default {
  cursor: pointer;
  display: block;
  outline: 0;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #666;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  line-height: 17px;
  margin: 2px auto;
  text-align: center;
  background: #fff;
  position: relative;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default {
    padding: 15px;
    height: 50px;
    width: 50px;
  }
}
.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default:after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 7px;
  left: 10px;
  width: 10px;
  height: 1px;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default:after {
    position: absolute;
    right: 20px;
    bottom: 12px;
    left: 20px;
    width: 10px;
  }
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default:hover {
  color: #fff;
  background: #40769b;
}

.provider-profile {
  display: flex;
  width: 100%;
  padding: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #f6f6f6;
}
.provider-profile .avatar {
  height: 100px;
  width: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.provider-profile div {
  padding-left: 1rem;
}

.selectdog input {
  opacity: 0;
  position: absolute;
}
.selectdog input + label {
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  overflow: hidden;
  position: relative;
  margin: 8px 0;
  -webkit-border-radius: 1.1rem;
  -moz-border-radius: 1.1rem;
  border-radius: 1.1rem;
  -webkit-box-shadow: 0 0 35px rgba(64, 118, 155, 0.2);
  -moz-box-shadow: 0 0 35px rgba(64, 118, 155, 0.2);
  box-shadow: 0 0 35px rgba(64, 118, 155, 0.2);
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.selectdog input + label .avatarimg {
  height: 100px;
  width: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin: 0 auto 6px;
}
@media (min-width: 768px) {
  .selectdog input + label h6 {
    font-size: 1.3rem;
  }
}
.selectdog input + label p {
  display: inline-flex;
}
.selectdog input + label .edit {
  position: absolute;
  right: 50%;
  bottom: 0;
  padding: 0 6px;
  height: 30px;
  border: 0;
  text-align: center;
  width: 50px;
  -webkit-border-radius: 1rem 1rem 0 0;
  -moz-border-radius: 1rem 1rem 0 0;
  border-radius: 1rem 1rem 0 0;
  margin-right: -25px;
}
.selectdog input:checked + label:before {
  content: "";
  z-index: 10;
  border: 4px solid #40769b;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-border-radius: 1.1rem;
  -moz-border-radius: 1.1rem;
  border-radius: 1.1rem;
}
.selectdog input:checked + label:after {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 50px;
  width: 50px;
  content: "";
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  font-weight: bold;
  padding: 1rem 1rem 8px 8px;
  font-size: 1rem;
  font-family: "themify";
  background-color: #40769b;
}

.booking-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.booking-tabs li {
  white-space: nowrap;
  padding-right: 1rem;
  padding-left: 1rem;
}
.booking-tabs li a {
  text-transform: capitalize;
  display: inline-flex;
  position: relative;
  font-weight: 600;
  padding-right: 0;
  padding-left: 0;
}
.booking-tabs li a i {
  filter: grayscale(100%);
}
.booking-tabs li a.active {
  color: #40769b;
}
.booking-tabs li a.active i {
  filter: none;
}
.booking-tabs li a.active:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #40769b;
}

.round-box {
  padding: 2rem 1.25rem;
  -webkit-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  margin: 1rem 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.booking-item {
  padding: 2rem 1.25rem;
  -webkit-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  box-shadow: 0 0px 30px rgba(196, 197, 197, 0.4);
  -webkit-border-radius: 1rem 3rem 1rem 3rem;
  -moz-border-radius: 1rem 3rem 1rem 3rem;
  border-radius: 1rem 3rem 1rem 3rem;
  margin: 1rem 0;
}
@media (min-width: 1024px) {
  .booking-item {
    margin: 2rem 0;
  }
}
.booking-item .avatarimg {
  height: 100px;
  width: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin: 0 auto 6px;
}
.booking-item .features p {
  display: inline-flex;
  align-items: center;
}
.booking-item .features p i {
  margin-right: 4px;
}

.mypet-box {
  margin: 0.5rem;
  padding: 1rem;
  -webkit-box-shadow: 0 0 25px rgba(196, 197, 197, 0.4);
  -moz-box-shadow: 0 0 25px rgba(196, 197, 197, 0.4);
  box-shadow: 0 0 25px rgba(196, 197, 197, 0.4);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
@media (min-width: 1024px) {
  .mypet-box {
    margin: 1rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    border-radius: 1.5rem;
    padding: 1.25rem;
  }
}

.profile-progress {
  position: relative;
}
.profile-progress::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  left: 2rem;
  height: 8px;
  margin-top: -4px;
  background-color: #d7e2ea;
}
@media (max-width: 1023.98px) {
  .profile-progress img {
    max-width: 50px;
  }
}

@media (max-width: 1023.98px) {
  .services-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
}
.services-tabs .nav-item {
  padding: 0 0.5rem;
}
@media (max-width: 1023.98px) {
  .services-tabs .nav-item {
    white-space: nowrap;
  }
}
.services-tabs .nav-item:first-child {
  padding-left: 0;
}
.services-tabs .nav-item:last-child {
  padding-right: 0;
}
.services-tabs .nav-item .nav-link {
  color: #40769b;
  min-width: 100px;
  background-color: #f6f6f6;
  text-transform: capitalize;
  border-radius: 5rem;
  color: #3e3f49;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  padding: 0.75rem;
}
.services-tabs .nav-item .nav-link i {
  margin-right: 8px;
}
.services-tabs .nav-item .nav-link.completed {
  box-shadow: none;
  color: #ffffff;
  position: relative;
}
.services-tabs .nav-item .nav-link.completed:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/service-provider-sprite.png");
  background-position: 0 88.065844%;
  background-size: 235.294118%;
}
.services-tabs .nav-item .nav-link.active {
  box-shadow: none;
  color: #ffffff;
  border-color: #40769b;
}
.services-tabs .nav-item .nav-link.sitter.active,
.services-tabs .nav-item .nav-link.sitter.completed {
  background-color: #40ce0f !important;
}
.services-tabs .nav-item .nav-link.walker.active,
.services-tabs .nav-item .nav-link.walker.completed {
  background-color: #fdb41d !important;
}
.services-tabs .nav-item .nav-link.boarder.active,
.services-tabs .nav-item .nav-link.boarder.completed {
  background-color: #e40732 !important;
}
.services-tabs .nav-item .nav-link.groomer.active,
.services-tabs .nav-item .nav-link.groomer.completed {
  background-color: #1c59a1 !important;
}
.services-tabs .nav-item .nav-link.veterinary.active,
.services-tabs .nav-item .nav-link.veterinary.completed {
  background-color: #b06e33 !important;
}

.sitter-bg {
  background-color: #40ce0f !important;
}

.walker-bg {
  background-color: #fdb41d !important;
}

.border-bg {
  background-color: #e40732 !important;
}

.groomer-bg {
  background-color: #1c59a1 !important;
}

.Veterinary-bg {
  background-color: #b06e33 !important;
}

.experience-radio {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1023.98px) {
  .experience-radio {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1023.98px) {
  .experience-radio div {
    white-space: nowrap;
  }
}
.experience-radio .label-button {
  min-width: 120px;
  padding: 10px;
  font-weight: normal;
  background-color: #f6f6f6;
}
@media (min-width: 1024px) {
  .experience-radio .label-button {
    margin-right: 1rem;
  }
}

.service-groomtab li {
  padding: 0;
}
.service-groomtab li a {
  padding-right: 1rem;
  padding-left: 1rem;
}
.service-groomtab li a:before {
  content: "";
  background-color: #c4c5c5;
  height: 16px;
  width: 2px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  left: 0;
}
.service-groomtab li a.active:after {
  width: 40px;
  height: 3px;
  margin: auto;
}

.add-photos {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1023.98px) {
  .add-photos {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
}
.add-photos li {
  list-style-type: none;
  width: 30%;
  margin-right: 1rem;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
}
@media (max-width: 1023.98px) {
  .add-photos li {
    white-space: nowrap;
  }
}
@media (min-width: 1024px) {
  .add-photos li {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .add-photos li {
    width: 13%;
  }
}
.add-photos li div {
  position: relative;
}
.add-photos li div .serviceImg {
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
}
.add-photos li div button {
  background-color: transparent;
  border: 0px transparent;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.businessCheckbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
  .businessCheckbox {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
}
.businessCheckbox div {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 1199.98px) {
  .businessCheckbox div {
    white-space: nowrap;
  }
}
.businessCheckbox .label-button {
  min-width: 120px;
  border: 2px solid transparent;
  padding: 10px 1.25rem;
  font-weight: normal;
  background-color: #f6f6f6;
}
@media (min-width: 1024px) {
  .businessCheckbox .label-button {
    margin-right: 1rem;
  }
}
.businessCheckbox input:checked + .label-button.active {
  border-color: #40769b;
  color: #40769b !important;
  background-color: rgba(64, 118, 155, 0.25);
}
.businessCheckbox input:checked + .label-button.completed {
  border-color: #40769b;
  position: relative;
  text-align: left;
}
.businessCheckbox input:checked + .label-button.completed:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1rem;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  max-width: 100%;
  background-size: 100%;
  background-image: url("./images/service-provider-sprite.png");
  background-position: 0 88.065844%;
  background-size: 235.294118%;
}

.select-dropdown-menu {
  border: 0;
  padding: 1rem;
  min-width: 350px;
  max-width: auto;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.75rem 1.5rem #ececec;
  -moz-box-shadow: 0 0.75rem 1.5rem #ececec;
  box-shadow: 0 0.75rem 1.5rem #ececec;
}

.revenue-banner {
  position: relative;
}
.revenue-banner img {
  width: 100%;
  min-height: 200px;
  border-radius: 2rem;
}
.revenue-banner .overlayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.input-select input {
  position: absolute;
  opacity: 0;
}
.input-select input + label {
  position: relative;
  cursor: pointer;
}
.input-select input + label:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  border: 3px solid #c4c5c5;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  border-radius: 4rem;
}
.input-select input:checked + label:after {
  content: "";
  color: #ffffff;
  padding: 7px 8px;
  font-size: 1rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #40769b;
  border: 3px solid #40769b;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  border-radius: 4rem;
}

/*# sourceMappingURL=custom.css.map */

.select_div::before {
  z-index: 0;
}

.home-banner .caption .hero-form .form-control {
  color: #000 !important;
  background: transparent !important;
  position: relative !important;
}

.my-accounts .form-group .form-control {
  color: #000 !important;
  background: transparent !important;
  position: relative !important;
}
