.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.error {
  color: red;
  font-size: small;
}

.PhoneInputCountryIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 transparent;
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-style: solid;
  border-color: #000;
  border-top-width: 0;
  border-bottom-width: 2px;
  border-left-width: 0;
  border-right-width: 2px;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 0;
}

.predictions {
  position: absolute;
  top: 100%;
  background: white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  list-style-type: none;
  z-index: 99;
  width: 100%;
  left: 0;
}
